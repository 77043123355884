import {
  CallToActionSection,
  FAQSection,
  FeatureSection,
  HeroSection,
  HomeFooter,
  HomeHeader,
  LogoCloudSection,
  PricingSection,
  TestimonialSection,
} from '~/components/home-components';
import { ScrollArea } from '~/components/ui/scroll-area';

// export async function loader() {
//   return redirect('/auth/sign-in');
// }

export default function IndexPage() {
  return (
    <>
      <ScrollArea>
        <div className="bg-white">
          <HomeHeader />
          <main className="isolate">
            <HeroSection />
            <LogoCloudSection />
            <FeatureSection />
            <TestimonialSection />
            <PricingSection />
            <FAQSection />
            <CallToActionSection />
          </main>
          <div className="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
            <HomeFooter />
          </div>
        </div>
      </ScrollArea>
    </>
  );
}
